import React, { useState } from "react";

const listingFee = 0.2;
const transactionFee = 0.065;
const processingFee = 0.03;
const totalCost = 0.03;
const profit = 0.03;

const EtsyCalculator = () => {
  const [sellingPrice, setSellingPrice] = useState("");
  const [productCost, setProductCost] = useState("");
  const [shippingCost, setShippingCost] = useState("");
  const [fees, setFees] = useState({
    listingFee,
    transactionFee,
    processingFee,
    totalCost,
    profit,
  });

  const calculateFees = () => {
    const updatedTransactionFee = transactionFee * sellingPrice;
    const updatedProcessingFee = processingFee * sellingPrice + 0.25;

    const totalCost =
      Number(productCost) +
      Number(shippingCost) +
      Number(listingFee) +
      Number(updatedTransactionFee) +
      Number(updatedProcessingFee);
    const profit = Number(sellingPrice) - totalCost;

    return {
      listingFee,
      transactionFee: updatedTransactionFee,
      processingFee: updatedProcessingFee,
      totalCost,
      profit,
    };
  };

  const handleCalculate = (e) => {
    e.preventDefault();
    setFees(calculateFees());
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center bg-gray-200 my-4">
        <div className="bg-gray-200 p-10 w-full">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 pr-5">
              <h3 className="text-3xl font-bold mb-4">Etsy Calculator</h3>
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="sellingPrice"
                    className="block text-gray-800 font-bold mb-2"
                  >
                    Selling Price
                  </label>
                  <input
                    type="text"
                    id="sellingPrice"
                    name="sellingPrice"
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    placeholder="Enter selling price"
                    value={sellingPrice}
                    onChange={(e) => setSellingPrice(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="productCost"
                    className="block text-gray-800 font-bold mb-2"
                  >
                    Product Cost
                  </label>
                  <input
                    type="text"
                    id="productCost"
                    name="productCost"
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    placeholder="Enter product cost"
                    value={productCost}
                    onChange={(e) => setProductCost(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="shippingCost"
                    className="block text-gray-800 font-bold mb-2"
                  >
                    Shipping Cost
                  </label>
                  <input
                    type="text"
                    id="shippingCost"
                    name="shippingCost"
                    className="w-full border border-gray-300 p-2 rounded-lg"
                    placeholder="Enter shipping cost"
                    value={shippingCost}
                    onChange={(e) => setShippingCost(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full bg-black px-6 py-2 text-xl font-medium uppercase text-white"
                    onClick={handleCalculate}
                  >
                    Calculate
                  </button>
                </div>
              </form>
            </div>
            <div className="w-full md:w-1/2 flex flex-col justify-center mt-8 md:mt-0">
              <h3 className="text-4xl font-bold mb-4">Results</h3>
              <div className="bg-gray-100 p-4 rounded-lg w-full">
                {/* Display the calculated fees */}
                <p className="text-3xl font-bold mb-2">
                  Listing Fee: {"$" + fees.listingFee.toFixed(2)}
                </p>
                <p className="text-3xl font-bold mb-2">
                  Transaction Fee: {"$" + fees.transactionFee.toFixed(2)}
                </p>
                <p className="text-3xl font-bold mb-2">
                  Processing Fee: {"$" + fees.processingFee.toFixed(2)}
                </p>
                <p className="text-3xl font-bold mb-2">
                  Total Cost: {"$" + fees.totalCost.toFixed(2)}
                </p>
                <p className="text-3xl font-bold mb-2">
                  Profit: {"$" + fees.profit.toFixed(2)}
                </p>
                <span className="text-xs text-gray-500 mt-4">
                  *Only rough estimates
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-4">Etsy Fees & Taxes</h3>
        <p className="">
          Etsy users have to take into account multiple different fees, starting
          with payment processing and going all the way to marketing. Below
          mentioned fees are some of the mandatory fees Etsy applies. Please
          note, they are subject to change.
        </p>
        <ul className="list-disc space-y-2">
          <li>
            <strong>Listing Fee</strong> - Etsy charges a flat fee of 20 cents
            every time a customer purchases one of your listings
          </li>
          <li>
            <strong>Transaction Fee</strong> - Etsy charges a 6.5% transaction
            fee
          </li>
          <li>
            <strong>Payment Processing Fee</strong> - Etsy charges a payment
            processing fee of 3% + 25 cents for US users. If you live outside
            the US, please note that your payment processing fee may differ from
            this
          </li>
        </ul>
      </div>
    </>
  );
};

export default EtsyCalculator;
