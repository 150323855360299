/* eslint react/prop-types: 0 */
/* eslint react/display-name: 0  */
import React, { useState, useEffect } from "react";
import { MDXProvider } from "@mdx-js/react";
import { preToCodeBlock } from "mdx-utils";
import Code from "./src/components/code/Code";
import InlineRelatedLinks from "./src/components/blogs/inlineRelatedLinks/InlineRelatedLinks";
import Newsletter from "./src/components/newsletter/Newsletter";
import Favicon from "react-favicon";
import {
  EtsyProfitCalculator,
  HourlyToSalaryCalculator,
  TimeAndHalfCalculator,
} from "./src/components/calculators";
// import EtsyProfitCalculator from "./src/components/calculators/etsyProfitCalculator";

const calculatorComponents = {
  EtsyProfitCalculator,
  HourlyToSalaryCalculator,
  TimeAndHalfCalculator,
};

// const PopupModal = () => {
//   const [showPopup, setShowPopup] = useState(false);

//   useEffect(() => {
//     setTimeout(() => {
//       setShowPopup(true);
//     }, 50000);
//   }, []);

//   useEffect(() => {
//     window.addEventListener("mouseout", function mouseoutEvent(event) {
//       // Cancel the event as stated by the standard.
//       // event.preventDefault();
//       if (event.clientY <= 0 && !showPopup) {
//         setShowPopup(true);
//         window.removeEventListener("mouseout", mouseoutEvent);
//       }
//     });
//   }, []);

//   const handleClick = (e) => {
//     setShowPopup(false);
//   };

//   return (
//     <div
//       style={{
//         position: "fixed",
//         top: "0",
//         left: "0",
//         bottom: "0",
//         right: "0",
//         background: "rgba(0, 0, 0, 0.5)",
//         width: "100%",
//         height: "100%",
//         zIndex: "100000",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         display: showPopup ? "flex" : "none",
//       }}
//     >
//       <div
//         style={{
//           zIndex: "10001",
//           backgroundColor: "#fff",
//           padding: "10px",
//           borderRadius: "4px",
//           maxWidth: "400px",
//           width: "calc(100% - 30px)",
//           position: "relative",
//         }}
//       >
//         {/* <TiDeleteOutline
//           onClick={() => handleClick()}
//           style={{
//             position: "absolute",
//             top: "4px",
//             right: "4px",
//             cursor: "pointer",
//           }
//         }
//         /> */}
//         <i
//           className="text-center material-icons text-xl"
//           style={{
//             position: "absolute",
//             top: "6px",
//             right: "6px",
//             cursor: "pointer",
//           }}
//           onClick={() => handleClick()}
//         >
//           close
//         </i>
//         <Newsletter></Newsletter>
//       </div>
//     </div>
//   );
// };

// components is its own object outside of render so that the references to
// components are stable

const components = {
  pre: (preProps) => {
    const props = preToCodeBlock(preProps);
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />;
    }
    // it's possible to have a pre without a code in it
    return <pre {...preProps} />;
  },
  wrapper: ({ children }) => <>{children}</>,
  InlineRelatedLinks,
  ...calculatorComponents,
};
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>
    <Favicon url="https://i.imgur.com/cnY4LmY.webp" />
    {/* <PopupModal /> */}
    {element}
  </MDXProvider>
);
