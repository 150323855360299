import React from "react";
import * as styles from "./newsLetter.module.css";
import Subscribe from "./../home/subscribe/Subscribe";

const Newsletter = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <p className="text-2xl">
          (MME) Earn more - without sacrificing your day job
        </p>
        <p className="text-md">
          Don't sacrifice your day job to start earning more on the side! Join
          our community of 10k+ hustlers to learn how - in less than 5 minutes,
          every week. Get all my proven systems, case studies, and actionable
          tips to maximize your income.
        </p>
        <div className={styles.subscribe}>
          <Subscribe primaryAction="Subscribe"></Subscribe>
        </div>
        <span className="italic text-sm">
          Psst...! It's absolutely FREE. No spam, unsubscribe at any time.
        </span>
      </div>
    </div>
  );
};

export default Newsletter;
