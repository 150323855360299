import React, { useState } from "react";

const currencyOptions = ["USD", "EUR", "GBP", "INR"];
// const payRateOptions = ["Hourly", "Daily", "Weekly", "Monthly", "Annual"];
const payRateOptions = ["Hourly"];

const TimeAndHalfCalculator = () => {
  const [hourlyRate, setHourlyRate] = useState("");
  const [timeAndAHalfHoursWorked, settimeAndAHalfHoursWorked] = useState("");
  const [standardHours, setStandardHours] = useState("");
  const [totalPay, setTotalPay] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [payRateChoice, setPayRateChoice] = useState("Hourly");
  const [finalResults, setFinalResults] = useState({
    timeAndAHalfRate: 0,
    timeAndAHalfPay: 0,
    standardPay: 0,
    totalMoneyEarned: 0,
  });

  const formatCurrency = (number) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Math.floor(number));
  };

  const calculatePay = () => {
    let results = {
      timeAndAHalfRate: 0,
      timeAndAHalfPay: 0,
      standardPay: 0,
      totalMoneyEarned: 0,
    };

    // Convert input values to floating numbers
    const rate = parseFloat(hourlyRate);
    const timesAndAHalfHours = parseFloat(timeAndAHalfHoursWorked);
    const standardHoursWorked = parseFloat(standardHours);
    const timeAndAHalfRate = rate * 1.5;
    results.timeAndAHalfRate = timeAndAHalfRate;
    // results.timeAndAHalfPay =
    //   (timesAndAHalfHours - standardHoursWorked) * results.timeAndAHalfRate;
    // Check if the input values are numbers
    if (
      isNaN(rate) ||
      isNaN(timesAndAHalfHours) ||
      isNaN(standardHoursWorked)
    ) {
      console.error("Invalid input");
      return;
    }

    let totalPay = 0;

    // Calculate the total pay based on the pay rate choice
    switch (payRateChoice) {
      case "Hourly":
        // Pay for standard hours at the standard rate
        // totalPay += Math.min(timesAndAHalfHours, standardHoursWorked) * rate;
        // Pay for overtime hours at time and a half
        results.standardPay += standardHoursWorked * rate;
        results.timeAndAHalfPay += timesAndAHalfHours * timeAndAHalfRate;
        results.totalMoneyEarned +=
          results.standardPay + results.timeAndAHalfPay;

        break;
      // case "Daily":
      //   // Assuming a standard 8 hour day, adjust rates accordingly
      //   totalPay += standardHoursWorked * rate;
      //   if (timesAndAHalfHours > 8) {
      //     totalPay += (timesAndAHalfHours - 8) * rate * 1.5;
      //   }
      //   break;
      // case "Weekly":
      //   // Assuming a standard 40 hour week, adjust rates accordingly
      //   totalPay += standardHoursWorked * rate;
      //   if (timesAndAHalfHours > 40) {
      //     totalPay += (timesAndAHalfHours - 40) * rate * 1.5;
      //   }
      //   break;
      // case "Monthly":
      //   // Assuming a standard 160 hour month (40 timesAndAHalfHours/week * 4 weeks), adjust rates accordingly
      //   totalPay += standardHoursWorked * rate;
      //   if (timesAndAHalfHours > 160) {
      //     totalPay += (timesAndAHalfHours - 160) * rate * 1.5;
      //   }
      //   break;
      // case "Annual":
      //   // Assuming a standard 1920 hour year (40 timesAndAHalfHours/week * 48 weeks), adjust rates accordingly
      //   totalPay += standardHoursWorked * rate;
      //   if (timesAndAHalfHours > 1920) {
      //     totalPay += (timesAndAHalfHours - 1920) * rate * 1.5;
      //   }
      //   break;
      default:
        console.error("Invalid pay rate choice");
    }

    setTotalPay(totalPay.toFixed(2));
    setFinalResults(results);

    // Format the total pay to two decimal places
    return totalPay.toFixed(2);
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-200 my-4">
      <div className="bg-gray-200 p-10 w-full">
        <div className="flex flex-col md:flex-row items-baseline">
          <div className="w-full md:w-1/2 pr-5">
            <div className="mb-6">
              <label
                className="block text-gray-800 font-bold mb-2"
                htmlFor="currency"
              >
                Currency:
              </label>
              <div className="flex space-x-1">
                {currencyOptions.map((option) => (
                  <button
                    key={option}
                    className={`text-xs py-2 px-2 rounded transition-colors ${
                      currency === option
                        ? "bg-black text-white"
                        : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                    }`}
                    onClick={() => setCurrency(option)}
                    type="button"
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-800 font-bold mb-2"
                htmlFor="payRateChoice"
              >
                Pay rate choice:
              </label>
              <div className="flex space-x-1">
                {payRateOptions.map((option) => (
                  <button
                    key={option}
                    className={`text-xs py-2 px-2 rounded transition-colors ${
                      payRateChoice === option
                        ? "bg-black text-white"
                        : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                    }`}
                    onClick={() => setPayRateChoice(option)}
                    type="button"
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-800 font-bold mb-2"
                htmlFor="hourlyRate"
              >
                Standard hourly pay rate:
              </label>
              <input
                className="w-full border border-gray-300 p-2 rounded-lg"
                id="hourlyRate"
                type="number"
                placeholder="Hourly Rate"
                value={hourlyRate}
                onChange={(e) => setHourlyRate(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-800 font-bold mb-2"
                htmlFor="standardHours"
              >
                Standard hours worked:
              </label>
              <input
                className="w-full border border-gray-300 p-2 rounded-lg"
                id="standardHours"
                type="number"
                placeholder="Standard Hours"
                value={standardHours}
                onChange={(e) => setStandardHours(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-800 font-bold mb-2"
                htmlFor="timeAndAHalfHoursWorked"
              >
                Hours worked at time and a half:
              </label>
              <input
                className="w-full border border-gray-300 p-2 rounded-lg"
                id="timeAndAHalfHoursWorked"
                type="number"
                placeholder="Hours Worked"
                value={timeAndAHalfHoursWorked}
                onChange={(e) => settimeAndAHalfHoursWorked(e.target.value)}
              />
            </div>

            <button
              className="w-full bg-black px-6 py-2 text-xl font-medium uppercase text-white"
              type="button"
              onClick={calculatePay}
            >
              Calculate
            </button>
          </div>
          <div className="w-full md:w-1/2 flex flex-col justify-center mt-8 md:mt-0">
            <h3 className="text-2xl font-bold mb-4">Total Pay</h3>
            <div className="bg-gray-100 p-4 rounded-lg w-full">
              <p>
                Time and a Half Rate:{" "}
                {formatCurrency(finalResults.timeAndAHalfRate)}
              </p>
              <p>
                Time and a Half Pay:{" "}
                {formatCurrency(finalResults.timeAndAHalfPay)}
              </p>
              <p>Standard Pay: {formatCurrency(finalResults.standardPay)}</p>
              <p>
                Total Money Earned:{" "}
                {formatCurrency(finalResults.totalMoneyEarned)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeAndHalfCalculator;
