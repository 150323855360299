import React, { useState } from "react";

const currencyOptions = ["USD", "EUR", "GBP", "INR"];

const HourlyToSalaryCalculator = () => {
  const [currency, setCurrency] = useState("USD");
  const [hourlyPay, setHourlyPay] = useState("");
  const [workHoursPerWeek, setWorkHoursPerWeek] = useState("");
  const [workWeeksPerYear, setWorkWeeksPerYear] = useState("");
  const [annualSalary, setAnnualSalary] = useState("");
  const [monthlyPay, setMonthlyPay] = useState("");
  const [weeklyPay, setWeeklyPay] = useState("");

  const formatCurrency = (number) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Math.floor(number));
  };

  const calculateSalary = () => {
    const annualSalaryValue = hourlyPay * workHoursPerWeek * workWeeksPerYear;
    const monthlyPayValue = annualSalaryValue / 12;
    const weeklyPayValue = annualSalaryValue / 52;

    setAnnualSalary(formatCurrency(annualSalaryValue));
    setMonthlyPay(formatCurrency(monthlyPayValue));
    setWeeklyPay(formatCurrency(weeklyPayValue));

    // setAnnualSalary(annualSalaryValue.toFixed(2));
    // setMonthlyPay(monthlyPayValue.toFixed(2));
    // setWeeklyPay(weeklyPayValue.toFixed(2));
  };

  return (
    <div className="flex flex-col items-center justify-center bg-gray-200 my-4">
      <div className="bg-gray-200 p-10 w-full">
        <div className="flex flex-col md:flex-row items-baseline">
          <div className="w-full md:w-1/2 pr-5">
            <h3 className="text-2xl font-bold mb-4">
              Hourly to Salary calculator
            </h3>
            <div className="mb-6">
              <label
                className="block text-gray-800 font-bold mb-2"
                htmlFor="currency"
              >
                Currency:
              </label>
              <div className="flex space-x-1">
                {currencyOptions.map((option) => (
                  <button
                    key={option}
                    className={`text-xs py-2 px-2 rounded transition-colors ${
                      currency === option
                        ? "bg-black text-white"
                        : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                    }`}
                    onClick={() => setCurrency(option)}
                    type="button"
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="hourlyPay"
                className="block text-gray-800 font-bold mb-2"
              >
                Hourly Pay
              </label>
              <input
                type="number"
                id="hourlyPay"
                name="hourlyPay"
                value={hourlyPay}
                onChange={(e) => setHourlyPay(e.target.value)}
                className="w-full border border-gray-300 p-2 rounded-lg"
                placeholder="Enter hourly pay"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="workHoursPerWeek"
                className="block text-gray-800 font-bold mb-2"
              >
                Work Hours per Week
              </label>
              <input
                type="number"
                id="workHoursPerWeek"
                name="workHoursPerWeek"
                value={workHoursPerWeek}
                onChange={(e) => setWorkHoursPerWeek(e.target.value)}
                className="w-full border border-gray-300 p-2 rounded-lg"
                placeholder="Enter work hours per week"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="workWeeksPerYear"
                className="block text-gray-800 font-bold mb-2"
              >
                Work Weeks per Year
              </label>
              <input
                type="number"
                id="workWeeksPerYear"
                name="workWeeksPerYear"
                value={workWeeksPerYear}
                onChange={(e) => setWorkWeeksPerYear(e.target.value)}
                className="w-full border border-gray-300 p-2 rounded-lg"
                placeholder="Enter work weeks per year"
              />
            </div>
            <button
              onClick={calculateSalary}
              className="w-full bg-black px-6 py-2 text-xl font-medium uppercase text-white"
            >
              Calculate
            </button>
          </div>
          <div className="w-full md:w-1/2 flex flex-col justify-center mt-8 md:mt-0">
            <h3 className="text-2xl font-bold mb-4">Results</h3>
            <div className="bg-gray-100 p-4 rounded-lg w-full">
              <p className="mb-2">
                <span className="font-bold">Annual Salary:</span>{" "}
                {`${annualSalary}`}
              </p>
              <p className="mb-2">
                <span className="font-bold">Monthly Pay:</span>{" "}
                {`${monthlyPay}`}
              </p>
              <p className="mb-2">
                <span className="font-bold">Weekly Pay:</span> {`${weeklyPay}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HourlyToSalaryCalculator;
