import React from "react";
import { Link } from "gatsby";
import * as styles from "./inlineRelatedLinks.module.css";

const InlineRelatedLinks = ({ title, links = [] }) => {
  return (
    <div className={styles.sectionWrapper} role="alert">
      <h4>{title}</h4>
      {links.map((l) => (
        <Link
          className="inline-flex mb-2 items-center hover:underline"
          to={l.url}
          title={l.title}
        >
          <svg
            class="w-4 h-4 ms-2 mr-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>{" "}
          {l.title}
        </Link>
      ))}
    </div>
  );
};

export default InlineRelatedLinks;
