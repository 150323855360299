import React from "react";
import * as styles from "./subscribe.module.css";

const Subscribe = ({ primaryAction }) => {
  return (
    <div className={styles.subscribeForm}>
      <iframe
        title="substack-newsletter"
        src="https://sleeplessyogi.substack.com/embed"
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default Subscribe;
